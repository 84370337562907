@use "../../../tokens";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  // See https://fkhadra.github.io/react-toastify/how-to-style#override-css-variabless
  --toastify-color-light: #{tokens.$color-white};
  --toastify-color-dark: #{tokens.$color-grey-50};
  --toastify-color-info: #{tokens.$color-blue-50};
  --toastify-color-success: #{tokens.$color-green-50};
  --toastify-color-warning: #{tokens.$color-yellow-50};
  --toastify-color-error: #{tokens.$color-red-60};

  --toastify-text-color-info: #{tokens.$color-white};
  --toastify-text-color-success: #{tokens.$color-grey-50};
  --toastify-text-color-warning: #{tokens.$color-grey-50};
  --toastify-text-color-error: #{tokens.$color-white};

  --toastify-toast-min-height: $layout-md;
  --toastify-toast-max-height: $layout-md;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: tokens.$spacing-lg tokens.$layout-xl;
  background-color: tokens.$color-white;
  gap: tokens.$spacing-lg;

  h1 {
    font: tokens.$text-title-3xs;
  }

  .logo {
    max-width: 250px;
    width: 100%;
    max-height: 2rem;
  }

  button {
    flex-shrink: 0;
  }

  &.navDesktop {
    justify-content: flex-end;

    h1 {
      margin-right: auto;
    }

    ul {
      display: flex;

      a {
        color: tokens.$color-black;
        text-decoration: none;
        font-weight: 600;
        padding: tokens.$spacing-sm tokens.$spacing-md;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media screen and (max-width: tokens.$screen-xl) {
      display: none;
    }
  }
}

.content {
  flex: 1 0 auto;
}
